<template>
    <div class="Header" :style="'background-color:'+color.background">
      <div :style="'text-align:center;color:'+color.color"><h3>{{title}}</h3></div>
        <el-divider></el-divider>
        <div v-for="(item,index) in object" :key="index">
          <div class="number" :style="'color:'+color.color">
            <div style="font-size: 14px;">{{item.name}}</div>
            <el-tooltip :content="'共计：'+item.number+' 单'" placement="top">
              <div style="display: flex">
                <div style="font-size: 20px;" :class="color.one"></div>
                <div style="font-size: 16px;width: 60px;margin:2px 0 0 16px">
                  <count-to :startVal='0' :endVal="item.number" :duration='6000' :prefix="prefix"/>
                </div>
              </div>
            </el-tooltip>
            <div style="display: flex">
              <div style="font-size: 20px;" :class="color.two"></div>
              <div style="font-size: 16px;width: 80px;margin:2px 0 0 16px">
                <count-to :startVal='0' :endVal="item.money" :duration='6000' :prefix="prefix"/>
              </div>
            </div>
          </div>
        </div>
    </div>
</template>

<script>
    import countTo from "@/views/statistical/component/countTo";
    export default {
        name: "Statistical",
        components:{countTo},
        props:{
            title:{
                default() {
                    return {}
                }
            },
            object:{
            default() {
              return {
              }
            }
          },
            color:{
                default() {
                    return {}
                }
            },
            prefix:{
                default() {
                    return {

                    }
                }
            },
        }
    }
</script>

<style scoped>
.number{
    display: flex;
    margin-top: 10px;
    padding: 0 10px;
    justify-content: space-between;
}
.Header{
  background-color: #ffffff;
  padding: 30px 20px;
  border-radius: 20px;
  box-shadow: 0 2px 12px 0 rgba(120, 120, 120, 0.1);
}

</style>