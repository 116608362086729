<template>
    <div :id="dataInfo.type" ref="type" style="width: 100%; height: 100%;"></div>
</template>

<script>
    const echarts = require('echarts/lib/echarts');
    require('echarts/lib/component/title');
    require('echarts/lib/component/toolbox');
    require('echarts/lib/component/tooltip');
    require('echarts/lib/component/legend');
    require('echarts/lib/chart/pie');
    export default {
        name: "DataView",
        props:{
            data:{
                type:Array,

            },
            dataInfo:{
                default() {
                    return {}
                }
            },
        },
        mounted() {
            this.drawLine();
        },
        watch:{
            data(){
              this.drawLine();
          }
        },
        methods: {
            drawLine() {
                let that=this;
                let myChart = echarts.init(this.$refs.type)
                myChart.off('click')
                // 绘制图表
                myChart.setOption({
                    tooltip :{
                        triggerOn:"mousemove",
                        showContent:true,
                        borderWidth:1,
                    },
                    legend: {
                      type: 'scroll', //分页类型
                      pageIconColor: '#ff781f', //翻页箭头颜色
                      pageTextStyle:{
                        color: '#999', //翻页数字颜色
                      }, //翻页数字设置
                      pageIconSize: 13,
                      orient: 'vertical',
                      x:this.dataInfo.location,
                      y:'center',
                      formatter: function(name) {
                          var data = that.data;
                          var total = 0;
                          var tarValue;
                          for (var i = 0; i < data.length; i++) {
                              total += data[i].value;
                              if (data[i].name == name) {
                                  tarValue = data[i].value;
                              }
                          }
                          var v = tarValue;
                          var p = Math.round(((tarValue / total) * 100));
                          if (!p) p = 0
                          return `${name}${v}(${p}%)`;
                      },
                    },
                        series: [
                        {
                            type: 'pie',
                            radius: [20, 140],
                            center: this.dataInfo.center,
                            roseType: 'area',
                            itemStyle: {
                                borderRadius: 8
                            },
                            data: this.data
                        }
                    ]
                })
                myChart.on('click', function (params) {
                  that.$emit('detailed', params.data);
                })
            },
        }
    }
</script>

<style scoped>

</style>
