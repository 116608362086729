<template>
  <div>
    <div class="statistical" style="margin-top: 10px">
      <statistical style="width: 340px" title="本日订单" prefix="" :color="headerStyle.day" :object="countHeader.today"/>
      <statistical style="width: 340px" title="昨日订单" prefix="" :color="headerStyle.lastDay" :object="countHeader.yesterday"/>
      <statistical style="width: 340px" title="本月订单" prefix="" :color="headerStyle.mouth" :object="countHeader.month"/>
      <statistical style="width: 340px" title="本年订单" prefix="" :color="headerStyle.year" :object="countHeader.year"/>
    </div>
    <div class="statistical" style="margin-top: 30px;">
      <el-card shadow="always" v-loading="pieChartLoading">
        <div class="statistical">
          <h3 style="text-align: left;line-height: 0;margin-top: 10px">订单金额</h3>
          <div style="display: flex">
            <div>
              <el-select size="small" placeholder="类型" v-model="pieChart.flag" style="width: 140px;margin-right: 10px" @change="slectFlag">
                <div v-if="sysStudentType==3">
                  <el-option label="中招体育" value="1"></el-option>
                  <el-option label="少年" value="2"></el-option>
                  <el-option label="高考体育" value="4"></el-option>
                </div>
                <div v-if="sysStudentType!=3">
                  <el-option v-if="sysStudentType==1" label="中招体育" value="1"></el-option>
                  <el-option v-else label="少年" value="2"></el-option>
                  <el-option label="高考体育" value="4"></el-option>
                </div>
              </el-select>
            </div>
            <div>
              <el-select style="width: 140px;margin-right: 10px" clearable v-model="pieChart.type" class="m-2" placeholder="收款类型" size="small">
                <el-option
                    v-for="item in tradingType"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
            <div>
              <el-date-picker
                  style="width: 240px"
                  size="small"
                  v-model="pieChartDate"
                  type="daterange"
                  range-separator=" 至 "
                  start-placeholder="开始时间"
                  end-placeholder="结束时间"
              >
              </el-date-picker>
            </div>
            <div style="padding:8px 20px 0 40px;width: 0px" @click="handleFullScreen">
              <el-tooltip :content="`全屏`">
                <span class="el-icon-rank"></span>
              </el-tooltip>
            </div>
          </div>
        </div>
        <bread-view style="width: 750px; height: 400px;" v-on:detailed="detailed" :data="pieChartData.data" :data-info="pieChartData.info"/>
      </el-card>
      <el-card shadow="always" v-loading="histogramLoading">
        <div class="statistical">
          <h3 style="text-align: left;line-height: 0;margin-top: 10px">业绩排名</h3>
          <div style="display: flex">
            <select-area size="small" @areaListId="getAreaListId" @Flag="getFlag"/>
            <div>
              <el-date-picker
                  style="width: 240px"
                  size="small"
                  v-model="histogramDate"
                  type="daterange"
                  range-separator=" 至 "
                  start-placeholder="开始时间"
                  end-placeholder="结束时间"
              >
              </el-date-picker>
            </div>
            <div style="padding:8px 20px 0 40px;width: 0px" @click="handleFullScreen">
              <el-tooltip :content="`全屏`">
                <span class="el-icon-rank"></span>
              </el-tooltip>
            </div>
          </div>
        </div>
        <pillars-view  style="width: 750px; height: 400px;" v-on:histogramDetailed="histogramDetailed" :series="histogramData" :project="histogramProject.name"/>
      </el-card>
    </div>
  </div>
</template>

<script>
import PillarsView from "@/views/statistical/component/PillarsView";
import BreadView from "@/views/statistical/component/BreadView";
import Statistical from "@/views/statistical/component/Statistical";
import {orderHeader, orderLeft,CoachPerformanceHistogramSummary} from "@/api/count";
import selectArea from "../../components/SelectArea";
import SelectArea from "../../components/SelectArea";
export default {
  props:{
    selectArea
  },
  name: "Console",
  components: {SelectArea, BreadView, Statistical,PillarsView,},
  data() {
    return {
      sysStudentType:localStorage.getItem('studentType'),
      countHeader: {},
      headerStyle:{
        day:{
          background: '#ECF5FF',
          color: '#409EFF',
          one:'el-icon-s-promotion',
          two:'iconfont icon-qian4'
        },
        lastDay:{
          background: '#F0F9EB',
          color: '#67C23A',
          one:'el-icon-s-promotion',
          two:'iconfont icon-qian4'
        },
        mouth:{
          background: '#FDF6EC',
          color: '#E6A23C',
          one:'el-icon-s-promotion',
          two:'iconfont icon-qian4'
        },
        year:{
          background: '#FEF0F0',
          color: '#F56C6C',
          one:'el-icon-s-promotion',
          two:'iconfont icon-qian4'
        }
      },
      tradingType:[{value:1,label:"课程费用"},{value:2,label:"器材费用"},{value:3,label:"定金费用"}],

      pieChartLoading:false,
      pieChartDate:"",
      pieChart:{
        endDate:"",
        regionId:null,
        startDate:"",
        type:"",
        flag:null
      },
      pieChartData: {info: {type: "order", location: 'left', center: ['60%', '50%']}, data: []},

      histogramDate:"",
      regionList:[],
      regionId:[],
      histogram:{
        endDate:"",
        regionId:null,
        startDate:"",
      },//柱状图筛选条件
      histogramData: [],
      histogramProject:{},
      histogramLoading:false,
      series: [{data: [40, 50, 100], type: "bar"}],
    }
  },
  computed:{
    websocket() {
      return this.$store.state.webSocket.notifyObject;//这里返回vuex的state里的websocket数据
    },
  },
  created: function () {
    this.getHeaderStatistical();
    this.getLeftStatistical();
    this.getCourseHistogramSummary();

  },
  methods: {
    getFlag(val){ 
      this.histogram.flag = val
      this.getCourseHistogramSummary();
    },
    slectFlag(){ 
      this.getLeftStatistical()
    },
    getAreaListId(val){
      this.histogram.areaList = val;
      this.getCourseHistogramSummary();
    },
    //饼状图点击事件
    detailed(val){
      this.pieChart.regionId=val.id;
      this.getLeftStatistical();
    },
    // 获取头部数据
    getHeaderStatistical(){
      orderHeader().then(res => {
        if (res.code == 200) {
          this.countHeader = res.data
        } else {
          this.$message.error("获取顶部统计数据失败");
        }
      })
    },
    //获取左边饼状图数据
    getLeftStatistical(){
      this.pieChartLoading=true;
      orderLeft(this.pieChart).then(val=>{
        this.pieChartLoading=false;
        if (val.code == 200) {
          this.pieChartData.data = val.data
        
        } else {
          this.$message.error("获取饼状图统计数据失败");
        }
      })
    },
    //获取右边柱状图数据
    getCourseHistogramSummary(){
      this.histogramLoading=true;
      CoachPerformanceHistogramSummary(this.histogram).then(val=>{
        // console.log(val)
        this.histogramLoading=false;
        if (val.code == 200) {
         
          let histogramData = [{data:val.data.valueList, type: "bar"}]
          this.histogramData=histogramData;
          this.histogramProject={name:val.data.nameList}
          this.histogramData.data = val.data
        } else {
          this.$message.error("获取柱状图统计数据失败");
        }
      })
    },
    handleFullScreen() {
      let element = document.documentElement;
      if (this.fullscreen) {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        }
      } else {
        if (element.requestFullscreen) {
          element.requestFullscreen();
        } else if (element.webkitRequestFullScreen) {
          element.webkitRequestFullScreen();
        } else if (element.mozRequestFullScreen) {
          element.mozRequestFullScreen();
        } else if (element.msRequestFullscreen) {
          element.msRequestFullscreen();
        }
      }
      this.fullscreen = !this.fullscreen;
    },
    dateFormat(val) {
      var date = new Date(val)
      var y = date.getFullYear()
      var m = date.getMonth() + 1
      m = m < 10 ? ('0' + m) : m
      var d = date.getDate()
      d = d < 10 ? ('0' + d) : d
      const time = y + '-' + m + '-' + d
      return time
    },

  },
  watch:{
    //监听用户选择时间完成以后进行查询
    pieChartDate(){
      if (this.pieChartDate!=null && this.pieChartDate!="" && this.pieChartDate.length>0){
        this.pieChart.startDate= this.dateFormat(this.pieChartDate[0]);
        this.pieChart.endDate= this.dateFormat(this.pieChartDate[1]);
      }else {
        this.pieChart.startDate="";
        this.pieChart.endDate="";
      }
      this.getLeftStatistical();
    },
    //监听用户选择类型完成以后进行查询
    'pieChart.type': {
      handler() {
        this.getLeftStatistical();
      },
      deep: true
    },
    regionId:{
      handler() {
        let regionId="";
        for (const item of this.regionId) {
          regionId=regionId+item+","
        }
        this.histogram.regionId=regionId;
        this.getCourseHistogramSummary();
      },
      deep: true
    },
    //监听用户选择柱状图时间完成以后进行查询
    histogramDate(){
      if (this.histogramDate!=null && this.histogramDate!="" && this.histogramDate.length>0){
        this.histogram.startDate= this.dateFormat(this.histogramDate[0]);
        this.histogram.endDate= this.dateFormat(this.histogramDate[1]);
      }else {
        this.histogram.startDate="";
        this.histogram.endDate="";
      }
      this.getCourseHistogramSummary();
    },
    websocket:{
      handler(newVal) {
        // console.log(newVal, oval);//nval改变后的新数据，oval改变前的旧数据
        if (newVal.api=="order"){
          this.getHeaderStatistical();
          this.getLeftStatistical();
          this.getCourseHistogramSummary();
        }
      },
      deep: true, // 深度监听
      immediate: true,//立即执行
    }
  }

}
</script>

<style>
.ordersPicker {
  display: flex;
  justify-content: flex-end;
}

.statistical {
  display: flex;
  justify-content: space-between;

}
</style>
<style scoped>
.abow_dialog {
  display: flex;
  justify-content: center;
  align-items: Center;
  overflow: hidden;
}
.el-dialog {
  margin: 0 auto;
  height: 100%;
  overflow: hidden;
}
</style>
<style lang="scss">
.el-popover.my-el-popover {
  width: 20px;
}
.el-cascader__tags {
  display: inline-flex;
  margin-right: 60px;
  flex-wrap: nowrap;
}
</style>